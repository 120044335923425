export const defaultAppReviews = [
  {
    key: 1,
    rating: 5,
    heading: 'Networking within the fishing community',
    bodyText:
      'Excellent tool to help with networking within the fishing community. awesome to see where people are catching fish at, and where to plan your next fishing trip.',
    username: 'Rex Waechter',
  },
  {
    key: 2,
    rating: 5,
    heading: 'I’ve been able to learn and grow',
    bodyText:
      'I’ve been able to learn and grow more in my fishing skill set and have caught so much more fish since being on this!!! Not only can I scroll through it for a couple hours just for entertainment, but I also am learning which colors and baits are working on which spots, and what gear I should be using to get the best action with my lures!',
    username: 'Crobbyboy',
  },
  {
    key: 3,
    rating: 5,
    heading: 'Hot spots',
    bodyText:
      'This App is Amazing!!! Not only does where the Hot spots are, it also informs me what time of day are the fish usually biting and what others are catching them with. Which is AWESOME!!!!',
    username: 'RL',
  },
];
